<template>
  <div class="website-about" :style="{minHeight: height + 'px'}">
    <div class="website-content">
      <div class="animate__animated animate__rollIn about-title" ref="#website-app-rollIn">
        <p>APP DOWNLOAD</p>
        <h3><span>APP下载</span></h3>
      </div>
      <div class="about-content ">
        <div class="website-app-content">
          <img src="@/views/appshow/picture/phone_pc.png" class="animate__animated animate__slideInLeft app-page" ref="#website-app-slideInLeft"/>
          <div class="animate__animated animate__slideInRight website-app-right" ref="#website-app-slideInRight">
            <p class="title">满电行</p>
            <ul class="app-instruct">
              <li>微信支付宝扫一扫就能充电</li>
              <li>为您精确定位导航充电站</li>
              <li>下载客户端享受更多功能</li>
              <li>大数据信息爱车交流无障碍</li>
            </ul>
            <div class="app-down">
              <div class="app-down-item">
                <div class="qrcode" id="qrcode-android"></div>
                <a :href="qrLink.android"><i class="iconfont icon-anzhuo"></i>安卓版下载</a>
              </div>
              <div class="app-down-item">
                <div class="qrcode" id="qrcode-ios"></div>
                <a :href="qrLink.ios"><i class="iconfont icon-anzhuo"></i>苹果版下载</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: "website-about",
  data () {
    return {
      height: window.innerHeight,
      qrLink: {
        android: 'http://www.mdxxny.com/ApKFile/mdx_yyb.apk',
        ios: 'https://apps.apple.com/cn/app/id6450830591'
      },
      qrObj: {
        android: null,
        ios: null
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.createQRCode()
    })
  },
  methods: {
    createQRCode () {
      Object.keys(this.qrLink).forEach((key) => {
        let val = this.qrLink[key]
        if (val) {
          this.qrObj[key] = new QRCode('qrcode-' + key, {
            width: 150,
            height: 150,
            text: val,
            colorDark: '#000',
            colorLight: '#fff'
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "website";
.website-about{
  background: url("./index/bg8.jpg") center  center no-repeat;
  background-size: cover;
}
.website-app-content{
  display: flex;
  width: 80%;
  margin: 0 auto @base * 4 auto;
  .app-page{
    width: 300px;
    margin-right: @base * 4;
  }
  .website-app-right{
    color: #fff;
    .title{
      font-size: 54px;
      margin-bottom: @base * 4;
    }
    .app-instruct{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 50%;
        font-size: 24px;
        margin-bottom: @base * 2;
      }
    }
    .app-down{
      display: flex;
      width: 80%;
      margin: @base * 3 auto  auto;
      .app-down-item:nth-child(1){
        margin-right: @base * 8;
      }
      .app-down-item{
        text-align: center;
        a{
          color: #fff;
          text-decoration: underline;
          background: rgba(0,0,0,0.45);
          display: inline-block;
          padding: @base / 2 @base ;
        }
        .qrcode{
          margin-bottom: @base;
          border: 1px solid #dfdfdf;
          padding: @base * 2;
          background: #fff;
        }
      }
    }
  }
}


@media (max-width: 991px) {
  .website-app-content{
    width: 90%;
    .app-page{
      width: 250px;
      height: auto;
      margin-right: @base * 2;
    }
    .website-app-right{
      .title{
        font-size: 24px;
      }
      .app-instruct{
        li{
          font-size: 17px;
        }
      }
      .app-down{
        width: auto;
        .app-down-item:nth-child(1){
          margin-right: @base * 2;
        }
        .app-down-item{
          .qrcode{
            padding: @base;
          }
       }
     }
    }
  }
}

@media (max-width: 768px) {
  .website-app-content{
    flex-wrap: wrap;
    .app-page{
      margin: auto;
    }
  }
}
</style>
