<template>
  <div class="website-about" :style="{minHeight: height + 'px'}">
    <div class="website-content">
      <div class="animate__animated animate__rollIn about-title" ref="#website-invite-rollIn">
        <p>PARTNER</p>
        <div class="about-subtitle">
          <h3><span>合作伙伴</span></h3>
          <div class="about-more">更多</div>
        </div>
      </div>
      <!--<div class="about-content">
        <ul class="invite-wrapper">
          <li class="animate__animated animate__slideInLeft name" ref="#website-invite-slideInLeft">
            <p class="title">简历投递邮箱: 58220937@qq.com</p>
          </li>
          <li class="animate__animated animate__rubberBand c#" ref="#website-invite-rubberBand">
            <p class="title">C#.net工程师</p>
            <p class="job-tit">岗位内容</p>
            <p class="content">
              <span>1、本科以上学历;</span>
              <span>2、3年以上实际开发经验;</span>
              <span>3、熟悉多线程开发;</span>
              <span>3.熟悉消息中间件及缓存技术;</span>
              <span>4、熟悉SQL，Oracle等主流数据库;</span>
              <span>5、熟悉系统集成、CS/BS客户端开发，有物联网开发经验者优先考虑;</span>
              <span>6、有独立项目研发经验者优先;</span>
            </p>
          </li>
          <li class="animate__animated animate__rubberBand web" ref="#website-invite-rubberBand">
            <p class="title">Web前端开发工程师</p>
            <p class="job-tit">岗位内容</p>
            <p class="content">
              <span>1、大专及以上学历，1年以上开发经验，计算机等相关专业;</span>
              <span>2、理解并掌握Web前端技术，HTML5、CSS3、JavaScript等;</span>
              <span>3、对CSS/JavaScript性能优化、解决多浏览器兼容性问题有一定的经验;</span>
              <span>4、熟练使用流行的前端框架组件库，如Vuejs、Antd、echarts等;</span>
              <span>5、代码工整，可读性强，可维护性强，有一定的文档编写能力;</span>
              <span>6、对用户体验、交互操作流程、及用户需求有一定分析与理解能力;</span>
            </p>
          </li>
          <li class="animate__animated animate__slideInRight after" ref="#website-invite-slideInRight">
            <p class="title">售后工程师</p>
            <p class="job-tit">岗位内容</p>
            <p class="content">
              <span>1、中专以上学历;</span>
              <span>2、对sql,oracle数据库简单操作有所了解;</span>
              <span>3、吃苦耐劳，能坚持长期出差;</span>
              <span>4、具有良好的客户沟通关系;</span>
              <span>5、负责完成我公司设备的交付，调试，测试，维护;</span>
              <span>6、负责日常的技术支持、维护，及客户培训工作;</span>
            </p>
          </li>
        </ul>
      </div>-->
      <ul class="animate__animated animate__flipInX text-animate partner-box" ref="#website-invite-flipInX">
        <li v-for="(p, pInd) in partnerList" :key="pInd">
          <img :src="p.logo" v-if="p.logo">
          <span>{{p.text}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "website-invite",
  data () {
    return {
      height: window.innerHeight,
      partnerList: [
        {
          logo: require('./invite/hhdl.jpg'),
          text: '四川大学'
        },
        {
          logo: require('./invite/kd.webp'),
          text: '快电'
        },
        {
          logo: require('./invite/xdt.png'),
          text: '新电途'
        },
        {
          logo: require('./invite/wm.webp'),
          text: '万马'
        },
        {
          logo: require('./invite/xl.webp'),
          text: '星络充电'
        },
        {
          logo: require('./invite/gjdw.jpg'),
          text: '国家电网'
        },
        {
          logo: require('./invite/lx.webp'),
          text: '联想'
        },
        {
          logo: require('./invite/zkxr.jpg'),
          text: 'IBM'
        },
        {
          logo: require('./invite/fyc.jpg'),
          text: '凡亚充'
        },
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
  }
}
</script>

<style scoped lang="less">
@import "website";
.website-about{
  background: url("./index/bg8.jpg") bottom center no-repeat;
  background-size: cover;
  position: relative;
}
// .website-about::before{
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-color: rgba(38,142,194,.5);
//     z-index: 1;
//   }
</style>
