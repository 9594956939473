<template>
  <div class="website-index" :style="{minHeight: height + 'px'}">
    <Carousel v-model="bannerInd"
              :height="height"
              @on-change="bannerChange"
              :radius-dot="true"
              :autoplay="true"
              :autoplay-speed="5000">
      <CarouselItem v-for="(banner, bInd) in bannerList" :key="bInd">
        <div class="banner-box">
          <img :src="banner.bac" class="banner-bg">
          <div class="text-img">
            <img :src="banner.text.left"
                 class="animate__animated banner-text-img"
                 :ref="'bannerSlideLeft_' + bInd"
                 v-if="banner.text.left">
            <img :src="banner.text.right"
                 class="animate__animated banner-text-img"
                 v-if="banner.text.right"
                 :ref="'bannerSlideRight_' + bInd">
          </div>
<!--          <div class="animate__animated animate__slideInRight app-download" v-if="bInd === 0"  :ref="'bannerSlideRight_' + bInd">
            <p class="title">APP下载：</p>
            <div class="qrcode-box">
              <div class="qrcode" id="qrcode-android"></div>
              <a :href="qrLink.android">安卓版下载</a>
            </div>
&lt;!&ndash;            <div class="qrcode-box">
              <div class="qrcode" id="qrcode-ios"></div>
              <a href="http://www.mdxxny.com/ApKFile/mdx_yyb.apk">苹果版下载</a>
            </div>&ndash;&gt;
          </div>-->
        </div>
      </CarouselItem>
    </Carousel>
  </div>
</template>

<script>
export default {
  name: "website-index",
  data () {
    return {
      height: window.innerHeight - 80,
      bannerInd: 0,
      bannerList: [
       /* {
          bac: require('./index/bg1.png'),
          text: {
            // left: require('./index/text1.png')
          }
        },*/
        {
          bac: require('./index/bg2.png'),
          text: {
            left: require('./index/text3.png'),
            right: require('./index/text4.png')
          }
        },
        {
          bac: require('./index/bg3.png'),
          text: {
            left: require('./index/text5.png'),
            right: require('./index/text6.png')
          }
        }
      ],
      qrLink: {
        android: 'http://www.mdxxny.com/ApKFile/mdx_yyb.apk',
        ios: ''
      },
      qrObj: {
        android: null,
        ios: null
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
  },
  methods: {
    bannerChange (oldVal, val) {
      this.bannerList.forEach((banner, index) => {
        if (val === index) {
          let leftRef = this.$refs['bannerSlideLeft_' + val]
          let rightRef = this.$refs['bannerSlideRight_' + val]
          leftRef && leftRef[0].classList.add('animate__slideInLeft')
          rightRef && rightRef[0].classList.add('animate__slideInRight')
        } else {
          let leftRef = this.$refs['bannerSlideLeft_' + index]
          let rightRef = this.$refs['bannerSlideRight_' + index]
          leftRef && leftRef[0].classList.remove('animate__slideInLeft')
          rightRef && rightRef[0].classList.remove('animate__slideInRight')
        }
      })
    }
  }
}
</script>

<style lang="less">
@import "website";
  .website-index{
    padding-top: @base * 10;
    .banner-box{
      width: 100%;
      height: 100%;
      position: relative;
      .banner-bg{
        width: 100%;
        height: 100%;
        display: block;
        margin: 0 auto;
        object-fit: cover;
      }
      .text-img{
        position: absolute;
        left: 50%;
        top: 35%;
        transform: translate(-50%,-35%);
        img{
          display: block;
          margin-bottom: @base;
        }
      }
      .banner-text-img{
        animation-duration: 2s;
      }
      .app-download{
        position: absolute;
        right: 20%;
        top: 35%;
        transform: translate(-30% -35%);
        .title{
          font-size: 20px;
          color: #fff;
          font-weight: bold;
          margin-bottom: @base;
        }
        .qrcode-box{
          text-align: center;
        }
        a{
          display: inline-block;
          margin-top: @base;
          color: #fff;
          font-size: 16px;
          text-decoration: underline;
          margin-right: @base * 2;
          &:hover{
            color: #00BFFF;
          }
        }
      }
    }
    .ivu-carousel-dots{
      bottom: @base * 1.5;
      li{
        button{
          background: rgba(255, 255, 255,.85);
          opacity: 1;
          width: 8px;
          height: 8px;
        }
        &.ivu-carousel-active{
          button{
            background: rgba(0,0,0,.75);
            width: 8px;
            opacity: 1;
          }
        }
      }
    }
  }


  @media (max-width: 1100px) {
    .website-index{
      padding-top: @base * 5.3;
       .banner-box{
        .text-img{
          width: 80%;
          img{
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }

@media (max-width: 768px) {
  .website-index{
      padding-top: @base * 5.3;
      .banner-box{
        .text-img{
          width: 60%;
          img{
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
  }
}

@media (max-width: 450px) {
  .website-index{
      padding-top: @base * 5.3;
  }
}
</style>
