<template>
  <div class="website-about" :style="{minHeight: height + 'px'}">
    <div class="website-content">
      <div class="contact-img">
        <img src="./contact/contact-bac.jpg" alt="">
      </div>
      <div class="about-content">
        <div class="animate__animated animate__rollIn about-title" ref="#website-contact-rollIn">
          <p>CONTACT US</p>
          <h3>联系我们 <span>尊敬的客户，我们24小时竭诚为您服务</span></h3>
        </div>
        <ul class="animate__animated animate__bounceIn contact-list" ref="#website-contact-bounceIn">
          <li v-for="(contact, cInd) in contactList" :key="cInd">
            <img :src="contact.icon" class="animate__animated"
                 @mouseenter="animateChange('enter', cInd)"
                 @mouseleave="animateChange('leave', cInd)"
                 ref="contactIcon">
            <span>
              <template v-if="contact.name">
                {{contact.name}}:
              </template>
              {{contact.phone}}
            </span>
          </li>
        </ul>
      </div>
<!--        <div class="animate__animated animate__flipInX contact-map" id="contact-map" ref="#website-contact-flipInX"></div>-->
    </div>
  </div>
</template>

<script>
import { mapKey, SafeJsCode } from '@/libs/api'
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: SafeJsCode, //安全密钥
}
export default {
  name: "website-contact",
  data () {
    return {
      contactList: [
        {
          name: '业务咨询',
          phone: '995595173@qq.com',
          icon: require('./contact/cont_a.png')
        },
        {
          name: '技术服务',
          phone: '5148364@qq.com',
          icon: require('./contact/cont_a.png')
        },
        {
          name: '',
          phone: '58220973@qq.com',
          icon: require('./contact/cont_c.png')
        },
        {
          name: '',
          phone: '成都市武侯区科华北路新棕北大厦4B07',
          icon: require('./contact/cont_d.png')
        }
      ],
      AMap: null,
      map: null,
      center: [104.07,30.63],
      height: window.innerHeight - 80
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        // this.initMap()
      }, 500)
    })
  },
  methods: {
    async initMap () {
      let _this = this
      this.AMap = await AMapLoader.load({
        key: mapKey,  //设置您的key
        version: "2.0"
      })
      this.map = new AMap.Map("contact-map", {
        viewMode: "2D",
        zoom: 16,
        mapStyleId: 'style1',
        center: this.center,
        resizeEnable: true,
      })
      let infoWindow = new AMap.InfoWindow({
        content: `
          <div class="infoWindow-wrapper" style="padding: 8px;">
            <p style="color: #666;">四川满电行新能源科技有限公司</p>
            <p style="color: #666;">成都市武侯区科华北路新棕北大厦4B07</p>
          </div>
        `
      })
      infoWindow.open(_this.map, _this.center)
    },
    animateChange (type, index) {
      if (type === 'enter'){
        this.$refs.contactIcon[index].classList.add('animate__bounce')
      } else if (type === 'leave') {
        this.$refs.contactIcon[index].classList.remove('animate__bounce')
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "website";
.website-about{
  //background-image: url("./contact/contact-bac.png");
  background: url("./index/bg4.jpg") bottom center no-repeat;
  background-size: cover;
  position: relative;
  .website-content{
    padding: @base * 20 0 @base;
    display: flex;
    flex-wrap: wrap-reverse;
    gap:  @base * 7;
    justify-content: center;
    .contact-img{
      display: block;
      max-width: 60%;
      img{
         max-width: 60%;
      }
    }
    .about-content{
      padding-top: 0;

    }
}
  .about-title{
    padding-top: 0;
    h3{
      font-size: 20px;
      &:before{
        height: 0;
      }
      span{
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 768px) {
  .website-about{
    .website-content{
      padding: @base * 10 @base * 7;
      justify-content: flex-start;
      .about-title{
        width: auto;
        h3{
          font-size: 16px;
          span{
            font-size: 12px;
          }
        }
      }
      .contact-img{
        max-width: 100%;
      }
    }
  }
}
</style>
