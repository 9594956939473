<template>
    <div class="website-about" :style="{minHeight: height + 'px'}">
      <div class="website-content">
        <div class="about-title" ref="#website-project-slideInLeft">
          <p class="animate__animated animate__slideInLeft">NEW CASES</p>
          <div class="about-subtitle">
            <h3 class="animate__animated animate__slideInLeft"><span>最新案例</span>一个优秀的作品，必然是理念设计和技术的完美结合</h3>
            <div class="animate__animated animate__slideInRight check-more">
              <span @click="currentIndex = 0" :style="{backgroundColor:currentIndex === 0 ? '#333' : '#31a59c'}">
                <Icon type="ios-arrow-back" />
              </span>
              <span @click="currentIndex = 1" :style="{backgroundColor:currentIndex === 1 ? '#333' : '#31a59c'}">
                <Icon type="ios-arrow-forward" />
              </span>
            </div>
          </div>
        </div>
        <div class="about-content" ref="#website-project-bounceIn">
          <Carousel v-model="currentIndex"
              arrow="never"
              dots="none"
              :autoplay="false"
              :autoplay-speed="5000">
            <CarouselItem v-for="(item,banner) in Math.ceil(( projectArr.length / 8))" :key="banner">
              <ul class="animate__animated animate__bounceIn project-ul">
                  <li v-for="(pro, iInd) in projectArr.slice(banner * 8 , (banner+1) * 8)" :key="iInd">
                    <div>
                      <img :src="pro.img" :title="pro.name">
                      <p v-if="pro.name">{{pro.name}}</p>
                    </div>
                  </li>
              </ul>
            </CarouselItem>
          </Carousel>
        <!-- <vue-seamless-scroll :data="projectArr" :class-option="classOption" class="wrap">
            <ul class="animate__animated animate__bounceIn project-ul" ref="#website-project-bounceIn">
              <li v-for="(pro, iInd) in projectArr" :key="iInd" :style="pro.styl">
                <img :src="pro.img" :title="pro.name">
              </li>
            </ul>
        </vue-seamless-scroll> -->
        </div>
      </div>
    </div>
</template>

<script>
// import vueSeamlessScroll  from 'vue-seamless-scroll'
export default {
  name: "website-project",
  components: {
    // vueSeamlessScroll
  },
  data () {
    return {
      classOption: {
        step: 0.5,
        direction: 2
      },
      projectArr: [
        {
          name: '七河路充电站（邦泰理想城售楼部）',
          img: require('./state-img/bangtai1.jpg'),
        },
        {
          name: '绥定大道二段充电站（杨柳公园3号停车场）',
          img: require('./state-img/yangliu1.jpg'),
        },
        {
          name: '华川顺丰顺物流充电站',
          img: require('./state-img/huachuan1.jpg'),
        },
        {
          name: '满电行好时尚广场',
          img: require('./state-img/haoshishang1.jpg'),
        },
        {
          name: '绥定大道二段充电站（杨柳公园3号停车场）',
          img: require('./state-img/yangliu2.jpg'),
        },
        {
          name: '罗浮广场充电站',
          img: require('./state-img/luofu2.jpg'),
        },
        {
          name: '南府公园充电站',
          img: require('./state-img/nanfu1.jpg'),
        },
        {
          name: '南国印染A区停车场充电站',
          img: require('./state-img/nanguoyinran1.jpg'),
        },
        {
          name: '达州升华广场充电站',
          img: require('./state-img/shenghua1.jpg'),
        },
        {
          name: '银杏柏栎酒店充电站',
          img: require('./state-img/yinxing1.jpg'),
        },
        {
          name: '邛崃充电站',
          img: require('./state-img/qionglai1.jpg'),
        },
        {
          name: '第六办公区电站',
          img: require('./state-img/diliubandongqu1.jpg'),
        },
        {
          name: '七河路充电站（邦泰理想城售楼部）',
          img: require('./state-img/bangtai2.jpg'),
        },
        {
          name: '七河路充电站（邦泰理想城售楼部）',
          img: require('./state-img/bangtai3.jpg'),
        },
        {
          name: '绥定大道二段充电站（杨柳公园3号停车场）',
          img: require('./state-img/yangliu3.jpg'),
        },
        {
          name: '罗浮广场充电站',
          img: require('./state-img/luofu1.jpg'),
        },
      ],
      height: window.innerHeight,
      currentIndex:0,
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
  }
}
</script>

<style scoped lang="less">
  @import "website";
  .website-about{
    background: url("./index/bg4.jpg") center center no-repeat;
    background-size: cover;
    .about-content{
      padding-top: 0;
    }
  }

  
</style>
