<template>
  <div class="website-about" :style="{minHeight: height + 'px'}">
    <div class="website-content">
      <div class="about-content">
        <ul class="animate__animated animate__slideInRight about-img" ref="#website-about-slideInRight">
          <li v-for="(img, iInd) in imgArr" :key="iInd">
           {{img}}
          </li>
        </ul>
        <div class="animate__animated animate__rollIn about-title" ref="#website-about-rollIn" style="padding-top: 20px;">
          <p>SERVICE</p>
          <h3>服务</h3>
        </div>
        <ul class="animate__animated animate__flipInX text-animate" ref="#website-about-flipInX">
          <li v-for="(t, tInd) in textArr" :key="tInd">
            <img :src="t.icon">
            <div>
              <span class="title">{{t.title}}</span>
              <span class="content" v-html="t.content"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "website-about",
  data () {
    return {
      imgArr: ['诚信', '博学', '报国', '求是'],
      height: window.innerHeight,
      textArr: [
        {
          icon: require('./about/icon-charge.png'),
          title: '充电模式',
          content: '微信小程序、支付宝小程序<br/>安卓APP、苹果IPA'
        },
        {
          icon: require('./about/icon-phone.png'),
          title: '专属APP',
          content: '随时随地查看充电进度<br>搜索附近充电桩、周遭环境'
        },
        {
          icon: require('./about/icon-manage.png'),
          title: '运营管理',
          content: '搜索附近充电桩、周遭环境<br>24小时轮班监控'
        },
        {
          icon: require('./about/icon-data.png'),
          title: '数据中心',
          content: '顶尖数据分析支持<br>为您清晰解析一切数据'
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
  @import "website";
  .website-about{
    position: relative;
    background: url("./index/bg8.jpg") center center no-repeat;
    background-size: cover;
    padding-top: @base * 6;
  }
  // .website-about::before{
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-color: rgba(38,142,194,.5);
  //   z-index: 1;
  // }
</style>
