<template>
  <div class="website-wrapper">
    <div class="website-header">
      <div class="website-header-content">
        <div class="logo">
          <img :src="logo" alt="" />
          <h4>四川满电行新能源科技有限公司</h4>
        </div>
        <div :class="['navbar',{active:checked}]">
          <Anchor>
            <AnchorLink
              v-for="(menu, mInd) in siteMenuList"
              :key="mInd"
              :title="menu.name"
              :href="menu.url"
            ></AnchorLink>
          </Anchor>
        </div>
        <a
          href="javascript:;"
          @click="$router.push('/login')"
          class="header-link"
          >商户登录</a
        >
        <label for="toggler" class="icon-meun">
          <Icon :type="checked ? 'md-close' : 'md-list' " />
        </label>
        <input v-model="checked" type="checkbox" id="toggler">
        
      </div>
    </div>
    <div class="website-router-content">
      <WebsiteIndex id="website-index" ref="#website-index"></WebsiteIndex>
      <WebsiteAbout id="website-about" ref="#website-about"></WebsiteAbout>
      <WebsiteProject
        id="website-project"
        ref="#website-project"
      ></WebsiteProject>
      <WebsiteInvite id="website-invite" ref="#website-invite"></WebsiteInvite>
      <WebsiteContact
        id="website-contact"
        ref="#website-contact"
      ></WebsiteContact>
      <WebsiteApp id="website-app" ref="#website-app"></WebsiteApp>
    </div>
    <div class="website-footer">
      <p>
        Copyright © 2011-2023
        <a :href="globalConfig.companyUrl" target="_blank">{{
          globalConfig.companyUrl
        }}</a>
        版权所有: {{ globalConfig.company }} 公网备案
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >蜀ICP备2023005104号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import globalConfig from "@/config";
import WebsiteIndex from "./website-index";
import WebsiteAbout from "./website-about";
import WebsiteProject from "./website-project";
import WebsiteInvite from "./website-invite";
import WebsiteContact from "./website-contact";
import WebsiteApp from "./website-app";
export default {
  name: "website-page",
  components: {
    WebsiteIndex,
    WebsiteAbout,
    WebsiteProject,
    WebsiteInvite,
    WebsiteContact,
    WebsiteApp,
  },
  data() {
    return {
      globalConfig,
      logo: require("@/assets/logo.png"),
      siteMenuList: [
        {
          name: "网站首页",
          url: "#website-index",
        },
        {
          name: "选择我们",
          url: "#website-about",
        },
        {
          name: "项目案例",
          url: "#website-project",
        },
        {
          name: "合作伙伴",
          url: "#website-invite",
        },
        {
          name: "加入我们",
          url: "#website-contact",
        },
        {
          name: "APP下载",
          url: "#website-app",
        },
      ],
      activeIndex: 0,
      checked:false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.toScroll);
    });
  },
  methods: {
    toScroll() {
      if(this.checked){
        this.checked = false;
      }
      let scrollPosition = document.documentElement.scrollTop;
      this.siteMenuList.forEach((item, index) => {
        let sectionTop = this.$refs[item.url].$el
          ? this.$refs[item.url].$el.offsetTop
          : 0;
        let sectionHeight = this.$refs[item.url].$el
          ? this.$refs[item.url].$el.offsetHeight
          : 0;
        if (
          scrollPosition >= sectionTop - sectionHeight / 3 &&
          scrollPosition < sectionTop + sectionHeight - sectionHeight / 3
        ) {
          this.activeIndex = index;
        }
      });
    },
  },
  watch: {
    activeIndex(val) {
      this.siteMenuList.forEach((item, index) => {
        let parentRef = this.$refs[item.url];
        let rollInRef = parentRef.$refs[item.url + "-rollIn"];
        let slideInRightRef = parentRef.$refs[item.url + "-slideInRight"];
        let flipInXRef = parentRef.$refs[item.url + "-flipInX"];
        let rubberBandRef = parentRef.$refs[item.url + "-rubberBand"];
        let slideInLeftRef = parentRef.$refs[item.url + "-slideInLeft"];
        let bounceInRef = parentRef.$refs[item.url + "-bounceIn"];
        let bannerSlideLeft = this.$refs["bannerSlideLeft_0"];
        if (val === index) {
          rollInRef && rollInRef.classList.add("animate__rollIn");
          slideInRightRef &&
            slideInRightRef.classList.add("animate__slideInRight");
          flipInXRef && flipInXRef.classList.add("animate__flipInX");
          rubberBandRef && rubberBandRef.classList.add("animate__rubberBand");
          slideInLeftRef &&
            slideInLeftRef.classList.add("animate__slideInLeft");
          bounceInRef && bounceInRef.classList.add("animate__bounceIn");
          if (val === 0) {
            bannerSlideLeft &&
              bannerSlideLeft.classList.add("animate__slideInLeft");
          }
        } else {
          rollInRef && rollInRef.classList.remove("animate__rollIn");
          slideInRightRef &&
            slideInRightRef.classList.remove("animate__slideInRight");
          flipInXRef && flipInXRef.classList.remove("animate__flipInX");
          rubberBandRef &&
            rubberBandRef.classList.remove("animate__rubberBand");
          slideInLeftRef &&
            slideInLeftRef.classList.remove("animate__slideInLeft");
          bounceInRef && bounceInRef.classList.remove("animate__bounceIn");
          if (index === 0) {
            bannerSlideLeft &&
              bannerSlideLeft.classList.remove("animate__slideInLeft");
          }
        }
      });
    }
  },
};
</script>


<style scoped lang="less">
.website-wrapper{
  overflow-x: hidden;
}
.website-header {
  padding: 0 @base * 3;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  .website-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  #toggler {
    display: none;
  }
  .icon-meun{
    display: none;
  }
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin-right: @base * 1.9;
    }
    h4 {
      font-size: 16px;
      font-weight: normal;
    }
  }
  a {
    font-size: 16px;
  }
  .header-link {
    font-size: 16px;
    color: #333;
    &:hover {
      color: #2b85e4;
    }
  }
  /deep/.ivu-anchor {
    display: flex;
    .ivu-anchor-link {
      padding: @base @base * 3;
      font-size: 16px;
    }
  }
}
.website-router-content {
  //background: #00b5f8;
  width: 100%;
  box-sizing: border-box;
  // padding-bottom: @base * 6;
}
.website-footer {
  text-align: center;
  padding: @base * 2 0;
}

@media (max-width: 1100px) {
  .website-header {
    .logo {
      img {
        width: 60px;
        height: 60px;
        margin-right: @base;
      }
      h4 {
        font-size: 14px;
      }
    }
    .navbar {
      /deep/.ivu-anchor {
        .ivu-anchor-link {
          padding: @base @base;
          font-size: 14px;
        }
      }
    }
    .meun-wrapper {
      a {
        font-size: 14px;
      }
      .icon-meun {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 6px 10px;
        font-size: 28px;
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .website-header {
    padding: 0 20px;
    .logo {
      img {
        width: 50px;
        height: auto;
        margin-right: @base;
      }
      h4 {
        font-size: 16px;
      }
    }
    .navbar {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      background-color: #fff;
      transform: scale(0);
      opacity: 0;
      transform-origin: top right;
      transition: none;
    }
    /deep/.ivu-anchor {
      display: initial;
      .ivu-anchor-link {
        padding: @base * 3 !important;
        font-size: 16px;
      }
    }
    a {
      display: none;
    }
    .icon-meun {
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 2px 8px;
      font-size: 18px;
      display: initial;
    }
    .active{
      transform: scale(1);
      opacity: 1;
      transition: .2s ease-out;
    }
  }
}

@media (max-width: 450px) {
  .website-header {
    padding: 0 10px;
    .logo {
      img {
        width: 40px;
        height: auto;
        margin-right: @base;
      }
      h4 {
        font-size: 14px;
      }
    }
    /deep/.ivu-anchor {
      display: initial;
      .ivu-anchor-link {
        padding: @base @base * 3;
        font-size: 16px;
      }
    }
    a {
      display: none;
    }
    .icon-meun {
      border: 1px solid #ccc;
      border-radius: 6px;
      font-size: 14px;
      display: initial;
    }
  }
}
</style>
